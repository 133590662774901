@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  font-family: 'poppins-semibold', Arial;
  background-color: #061011;
}

.nav-link {
  color:#fff
}
.breadcrumb {
  background-color: #061011!important;
}
.badge-num  {
  width: 34px;
  height: 34px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 5px;
}
.badge-num .red {
  background-color: red;

}
.badge-num .black {
  background-color: black;

}
.badge-num .green {
  background-color: green;

}
.border-1  {
  border:1px solid grey;
  border-radius: 10px;
}
.cursor {
  cursor: pointer;
}
.text-none {
  text-decoration: none!important;
  text-decoration-line: none!important;
  text-decoration: none!important; 

}
.numberBig {
  width: 65px;
  height: 65px;
}
.text-secondary h1, h2, h3, h4, h5 {
  /* color: #4CF517!important; */
}

.mw-100 {
  max-width: 100vh!important;
}